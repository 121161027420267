<template>
    <div class="col-12">
        <div class="card">
            <Loader v-model="loading"/>
            <Helper v-model="itemHelper" header="Buscar Artículo" :headers="itemHeaders" :rows="items" @selected="selectItem"/>
            <Helper v-model="warehouseHelper" header="Buscar Almacén" :headers="warehouseHeaders" :rows="warehouses" @selected="selectWarehouse"/>
            <Helper v-model="subwarehouseHelper" header="Buscar Subalmacen" :headers="subwarehouseHeaders" :rows="subwarehouses" @selected="selectSubwarehouse"/>
            <Helper v-model="warehouseHelper_destination" header="Buscar Almacén" :headers="warehouseHeaders" :rows="warehouses" @selected="selectWarehouse_destination"/>
            <Helper v-model="subwarehouseHelper_destination" header="Buscar Subalmacen" :headers="subwarehouseHeaders" :rows="subwarehouses" @selected="selectSubwarehouse_destination"/>
            <Panel header="Transferencia entre almacenes">
                <BasicFormToolbar  v-if="!view" @new="openNew" @list="list" :actions="['new', 'list']"/>
                    <Panel header="Origen">
                        <div class="p-fluid formgrid grid">
                            <FormInputText wrapperClass="field col-2" label="Artículo" v-model="item.id_item" @search="(itemHelper.visible=true)" :search="true" :valid="validateItem.validations.id_item" />
                            <FormInputText wrapperClass="field col-3" label="Almacén" v-model="item.inventory_warehouse_name" @search="(warehouseHelper.visible=true)" :search="true" :valid="validateItem.validations.id_inventory_warehouse" />
                            <FormInputText wrapperClass="field col-3" label="Subalmacen" v-model="item.inventory_subwarehouse_name" @search="(subwarehouseHelper.visible=true)" :search="true" :valid="validateItem.validations.id_inventory_subwarehouse"/>
                            <FormInputText wrapperClass="field col-2" label="Cantidad" v-model="item.quantity" :valid="validateItem.validations.quantity"/>
                            <FormInputText wrapperClass="field col-2" label="Existencia" v-model="item.stock" disabled="true" />
                        </div>
                    </Panel><br>
                    <Panel header="Destino">
                        <div class="p-fluid formgrid grid">
                            <FormInputText wrapperClass="field col-3" label="Almacén" v-model="item.inventory_warehouse_destination_name " @search="(warehouseHelper_destination.visible=true)" :search="true" :valid="validateItem.validations.id_inventory_warehouse_destination"/>
                            <FormInputText wrapperClass="field col-3" label="Subalmacen" v-model="item.inventory_subwarehouse_destination_name " @search="(subwarehouseHelper_destination.visible=true)" :search="true" :valid="validateItem.validations.id_inventory_subwarehouse_destination"/>
                        </div>
                    </Panel><br>
                    <div class="col-3">
                        <Button v-on:click="save" label="Transferir" class="p-button-warning mr-2" />
                    </div>
            </Panel><br>
            <Panel header="Consulta de traspasos">
                <div class="p-fluid formgrid grid">
                    <FormCalendar wrapperClass="field col-3" label="Fecha Inicial" v-model="entity.initial_date"/>
                    <FormCalendar wrapperClass="field col-3" label="Fecha Final" v-model="entity.final_date"/>
                </div>
                <BasicDatatable
                :headers="headers"
                :rows="transferences"
                :selectionMode="'single'"
                :headerVisible="false"
                />
            </Panel>
        </div>
    </div>
</template>

<script>
import Loader from "../../../components/general/Loader.vue"
import Session from "../../../mixins/sessionMixin";
import BasicFormToolbar from '../../../components/general/BasicFormToolbar.vue'
import FormInputText from '../../../components/general/FormInputText.vue';
import FormCalendar from "../../../components/general/FormCalendar.vue";
import BasicDatatable from "../../../components/general/BasicDatatable.vue";
import Helper from '../../../components/general/HelperDialog.vue';
import { InventoryArticle } from "../../../models/inventarios/InventoryArticle";
import { InventoryWarehouse } from "../../../models/inventarios/InventoryWarehouse";
import { InventorySubWarehouse } from "../../../models/inventarios/InventorySubWarehouse";
import { EntryExitWareHouse } from "../../../models/inventarios/EntryExitWareHouse";
import moment from 'moment';
import {
    HeaderGrid,
    fillObject,
    ErrorToast,
    validate,
    Rule,
    Toast
  } from "../../../utilities/General";
import { EntryExitItem } from "../../../models/inventarios/EntryExitItem";
import {TransferWarehouse} from "../../../models/inventarios/TransferWarehouse";

export default{
    mixins: [Session],
    props: {
    modal: null,
    },

    data(){
        return{
            loading: false,
            item: new EntryExitItem(this.session),
            items: [],
            entity: new EntryExitWareHouse(this.session),
            entities: [],
            warehouses:[],
            subwarehouses:[],
            transfer: new TransferWarehouse(this.session),
            transferences: [],
            warehouseHelper:{
              visible: false
            },
            subwarehouseHelper:{
              visible: false
            },
            warehouseHelper_destination:{
              visible: false
            },
            subwarehouseHelper_destination:{
              visible: false
            },
            itemHelper:{
              visible: false
            },
            headers:[
                new HeaderGrid('Fecha','date',{type:'date'}),
                new HeaderGrid('Almacén Origen','almacen_origen'),
                new HeaderGrid('Subalmacen Origen','subalmacen_origen'),
                new HeaderGrid('Almacén Destino','almacen_destino'),
                new HeaderGrid('Subalmacen Destino','subalmacen_destino'),
                new HeaderGrid('Articulo','articulo'),
                new HeaderGrid('Cantidad','quantity')
            ],
            itemHeaders:[
                new HeaderGrid('Artículo','id'),
                new HeaderGrid('Descripción','key_name'),
                new HeaderGrid('Costo','unit_price',{type:'currency'}),
                new HeaderGrid('Unidad de Medida','strdescriptionmeasure'),
            ],
            warehouseHeaders:[
            new HeaderGrid('Folio','id'),  
            new HeaderGrid('Nombre','name')
            ],
            subwarehouseHeaders:[
              new HeaderGrid('Folio','id'),  
              new HeaderGrid('Nombre','name')
            ],
            rulesItem: [
                new Rule({ name: "id_item" }),
                new Rule({ name: "quantity" }),
                new Rule({ name: "id_inventory_warehouse" }),
                new Rule({ name: "id_inventory_subwarehouse" }),
                new Rule({ name: "id_inventory_warehouse_destination" }),
                new Rule({ name: "id_inventory_subwarehouse_destination" }),
            ],
            validateItem: {
                valid: false,
                validations: {
                    id_item: null,
                    id_inventory_warehouse: null,
                    id_inventory_subwarehouse: null,
                    id_inventory_warehouse_destination: null,
                    id_inventory_subwarehouse_destination: null,
                    quantity: null,
                },
            },
        }
    },
    components:{Loader,BasicFormToolbar,FormInputText,BasicDatatable,Helper,FormCalendar},
    created() {
        this.entity = new EntryExitWareHouse(this.session);
        this.item = new EntryExitItem(this.session);
        this.transfer = new TransferWarehouse(this.session);
      },
    async mounted() {
        await this.refresh();
      },
    methods:{
        openNew() {
          this.entity = new EntryExitWareHouse(this.session);
          this.item = new EntryExitItem(this.session);
          this.transfer = new TransferWarehouse(this.session);
          this.entity.initial_date = new Date();
          this.entity.final_date = new Date();
          this.transferences = null;
        },
        async save() {
          try {
            //* Validacion de form
            this.loading = true;
            this.validateItem = validate(this.item, this.rulesItem);
            if (!this.validateItem.valid) {
            throw "Favor de validar los campos";
            }              
            else {
                //Salida del almacen origen
                this.entity.type_movement = 3;
                this.entity.movement = "Salida";
                if(this.item.quantity > this.item.stock){
                    this.refresh();
                    throw "El articulo no tiene suficiente existencia"
                }else{
                    this.saveItem();
                    let entity_1 = await this.entity.save();
                    //* Agregamos un dato extra
                    this.$emit('save', entity_1);
                    this.entities.push(entity_1);

                    this.entity.items = [];

                    //Entrada al almacen destino
                    this.entity.type_movement = 3;
                    this.entity.movement = "Entrada";
                    this.transfer.id_inventory_warehouse_origin = this.item.id_inventory_warehouse;
                    this.transfer.id_inventory_subwarehouse_origin = this.item.id_inventory_subwarehouse;
                    this.item.id_inventory_warehouse = this.item.id_inventory_warehouse_destination; 
                    this.item.id_inventory_subwarehouse = this.item.id_inventory_subwarehouse_destination;
                    this.saveItem();
                    //* Agregamos un dato extra
                    let entity = await this.entity.save();
                    this.$emit('save', entity);
                    this.entities.push(entity);
                    //Se agrega movimiento a la tabla de transferencias de almacen
                    this.transfer.date = this.entity.date;
                    this.transfer.id_inventory_warehouse_destination = this.item.id_inventory_warehouse_destination;
                    this.transfer.id_inventory_subwarehouse_destination = this.item.id_inventory_subwarehouse_destination;
                    this.transfer.id_item = this.item.id_item;
                    this.transfer.quantity = this.item.quantity;
                    let transfer = await this.transfer.save();
                    this.$emit('save', transfer);
                    this.transferences.push(transfer);
                    this.$toast.add(
                        new Toast({
                        summary: "Creacion",
                        detail: "Informacion guardada con exito",
                        })
                    );
            }
                            }
            this.entity = new EntryExitWareHouse(this.session);
            this.item = new EntryExitItem(this.session);
            this.transferences = [];
            this.entity.initial_date = new Date();
            this.entity.final_date = new Date();
          } catch (error) {
            this.$toast.add(new ErrorToast(error));
          } finally {
            this.loading = false;
          }
        },
        
        saveItem() {
            try {
                this.item.fillSession(this.session);
                this.item.type_movement = this.entity.type_movement;
                let item = { ...this.item};
                if (item.id_number) {
                    let index = this.entity.items.findIndex(x => x.id_number == this.item.id_number);
                    this.entity.items[index] = item;
                }else {
                    item.id_number = item.id_number ?? this.entity.items.length + 1;
                    this.entity.items.push(item);
                }
            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            }
        },

        async refresh() {
          this.loading = true;
          try {
            if (!this.modal) 
              this.items = await new InventoryArticle(this.session).getCustom();
              this.warehouses = await new InventoryWarehouse(this.session).all();
              this.entity.initial_date = new Date();
              this.entity.final_date = new Date();
              if (this.id) {
              this.entity.id = this.id;
              let entity = await this.entity.retrieve();
              this.entity = fillObject(this.entity, entity);
            }
          } catch (error) {
            this.$toast.add(new ErrorToast(error));
          } finally {
            this.loading = false;
          }
        },

        async list(error){
            this.loading = true;
            try{
                this.transferences = await new TransferWarehouse(this.session).data({
                    initial_date: moment(this.entity.initial_date).format(),
                    final_date: moment(this.entity.final_date).format()
                });
            }catch{
                this.$toast.add(new ErrorToast(error));
            }finally{
                this.loading = false;
            }
        },

        async selectWarehouse(payload){
            this.item.id_inventory_warehouse = payload.id;
            this.item.inventory_warehouse_name = payload.name;
            this.subwarehouses = await new InventorySubWarehouse(this.session).Subwarehouse(this.item.id_inventory_warehouse);
        },
        async selectItem(payload){
            this.item.measure_unit = payload.strdescriptionmeasure;
            this.item.id_item = payload.id;
            this.item = fillObject(this.item, payload);
            this.item.id_inventory_warehouse = null;
            this.item.id_inventory_subwarehouse = null;
            this.item.id = null;
        },
        async selectSubwarehouse(payload,stock){
            this.item.id_inventory_subwarehouse = payload.id;
            this.item.inventory_subwarehouse_name = payload.name;
            stock = await new InventorySubWarehouse(this.session).getStock(this.item.id_item, this.item.id_inventory_warehouse, this.item.id_inventory_subwarehouse);
            if(stock.stock != null){
                this.item.stock = stock.stock;
            }else{
                this.item.stock = 0;
            }
        },
        async selectWarehouse_destination(payload){
            this.item.id_inventory_warehouse_destination = payload.id;
            this.item.inventory_warehouse_destination_name = payload.name;
            this.subwarehouses = await new InventorySubWarehouse(this.session).Subwarehouse(this.item.id_inventory_warehouse_destination);
        },
        async selectSubwarehouse_destination(payload){
            this.item.id_inventory_subwarehouse_destination = payload.id;
            this.item.inventory_subwarehouse_destination_name = payload.name;
        },
    }
}
</script>

<style>
</style>