import {
    Abstract
} from './Abstract';

export class TransferWarehouse extends Abstract{
    constructor(session){
        super('inventarios/TransferWarehouse',session);
        this.date = null;
        this.id_inventory_warehouse_origin = null;
        this.id_inventory_subwarehouse_origin = null;
        this.id_inventory_warehouse_destination = null;
        this.id_inventory_subwarehouse_destination = null;
        this.id_item = null;
        this.quantity = null;
    }
}